.button {
    background-color: var(--background);
    color: var(--color);
    transition: all 300ms;
}

.button:hover {
    background-color: var(--hover-background);
    color: var(--hover-color);
}

.button:disabled {
    opacity: .3;
}