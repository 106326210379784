.testimonials {
    cursor: grab;
}

.testimonial .testimonial-actions {
    visibility: hidden;
}

.testimonials .splide__arrow {
    top: 100%;
    height: 4rem;
    width: 4rem;
    background-color: var(--accent-color);
    color: var(--accent-text-color);
    opacity: 1 !important;
}

.testimonials .splide__arrow svg {
    height: 3rem;
    width: 3rem;
    stroke: currentColor;
    fill: none;
}

.testimonials .splide__pagination {
    display: none;
}

.testimonials .splide__slide.is-active .testimonial {
    opacity: 1;
}

.testimonials .splide__slide.is-active blockquote {
    background-color: var(--accent-color);
    color: var(--accent-text-color);
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.testimonials .splide__slide.is-active blockquote span.arrow {
    opacity: 1;
}

.testimonials .splide__slide.is-active blockquote .testimonial-actions {
    visibility: visible;
}

.testimonials .testimonial {
    transition: all 300ms;
    opacity: .5;
}

.testimonials .testimonial blockquote {
    transition: all 300ms;
    position: relative;
    color: var(--text-color);
}

.testimonials .testimonial blockquote span.arrow {
    transition: all 300ms;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 48px;
    height: 48px;
    margin-top: -36px;
    margin-left: -34px !important;
    transform: rotate(-135deg);
    overflow: hidden;
    border-radius: .5rem;
}

.testimonials .testimonial blockquote span.arrow::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-width: 36px 36px 0 0;
    border-style: solid;
    border-color: var(--accent-color);
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-radius: .5rem;
}


@media screen(lg) {
    .testimonials .splide__arrow {
        top: 50%;
    }
}

