.scrolling-text {
    width: 100%;
}

.scrolling-text .scrolling-text-inner {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;   
}

.scrolling-text .scrolling-text-inner * {
    animation-duration: var(--speed) !important;
}

.scrolling-text .scrolling-text-inner * {
    transform: translateX(-20%);
    animation: scrolling-text linear infinite 15s;
    margin-bottom: 0;
}

.scrolling-text.forward .scrolling-text-inner {
    justify-content: flex-end;
}

.scrolling-text.forward .scrolling-text-inner * {
    transform: translateX(20%);
    animation: scrolling-text-forward linear infinite 15s;
}