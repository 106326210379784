.filter-select {
    padding: 0;
}

.filter-select .select__control {
    width: 100%;
    padding: .75rem 1rem;
    font-size: 1.2rem;
    border-radius: .25rem;
    border-width: 0;
    box-shadow: none;
}

.filter-select .select__control:focus {
    outline-width: 2px;
    @apply outline-primary-100;
}

.filter-select .select__placeholder {
    color: #9ca3af;
}

.filter-select .select__control .select__multi-value {
    @apply bg-primary;
    @apply text-white;
}

.filter-select .select__control .select__multi-value__label {
    @apply text-white;
}

.filter-select .select__indicator-separator {
    display: none;
}