.nonprofit-card img {
    transition: all 300ms;
}

.nonprofit-card:hover img {
    transform: scale(1.2,1.2);
}

.nonprofit-connect a {
    transition: all 300ms;
}

.nonprofit-connect a:hover {
    @apply bg-secondary;
    color: white;
}